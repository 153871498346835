/* *,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
} */
/* * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
} */

html,
body,
#root {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    overflow-y: overlay;
    overflow-x: hidden;
}

@media print {
    body,
    html {
        width: 100%;
        margin-top: 0;
        display: block;
    }
    * {
        overflow: visible !important;
    }
}

a:active,
a:visited,
a {
    text-decoration: none;
    color: inherit;
}

button {
    font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", sans-serif;
}

h5,
h6 {
    display: block;
    font-size: 1rem;
}

div,
p,
input,
textarea,
li {
    font-family: "Roboto", sans-serif;
}

input,
textarea {
    box-sizing: border-box;
}

h1 {
    font-weight: 400;
    letter-spacing: 1px;
}

h2 {
    font-weight: 200;
    line-height: 1.5;
    font-size: 1.75rem;
}

p {
    font-size: 0.9rem;
    line-height: 1.5;
    font-weight: 300;
}

form {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"] {
    -webkit-appearance: none;
    -moz-appearance: none;
}

::-moz-selection {
    background: #a2def9;
    color: #14315b;
    opacity: 0.5;
}

::selection {
    background: #a2def9;
    color: #14315b;
    opacity: 0.5;
}
