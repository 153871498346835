.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-property {
    text-align: center;
    font-size: 18px;
    background: #fff;
    max-height: 30rem;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: baseline;
}

.swiper-property img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
    color: white;
    padding: 0.25rem;
    height: 100%;
    top: 1rem;
}
.swiper-property-modal .swiper-button-prev,
.swiper-property-modal .swiper-button-next {
    color: #a2def9;
}

.swiper-property {
    cursor: zoom-in;
}

.swiper-button-prev {
    margin-left: -11px;
}
.swiper-button-next {
    margin-right: -11px;
}

.swiper-pagination.swiper-pagination-fraction {
    background: #ffffff94;
}

@media print {
    .swiper-pagination.swiper-pagination-fraction {
        display: none;
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    left: 45%;
    width: 10%;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 0.25rem 0;
}
